form {
    width: 100%;
}

form > .row {
    margin-bottom: 10px;
}

form > .btn {
    height: 40px;
    width: 100px;
}

.form-group > label {
    text-transform: uppercase;
    font-weight: bold;
}

.form-group > select {
    width: 40%;
}

.success {
    width: 100%;
    display: flex;
    justify-content: center;
}

.success > span {
    text-align: center;
}

.success-button {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    justify-content: center;
}

.success-button > .btn {
    width: 100px;
}