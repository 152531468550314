.status-btn {
    height: 40px;
    width: 100px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s
}

.no-found{
    margin: auto
}

#fit-icon{
    width:20px;
    height:20px;
}

#fit-button{
    width:20px;
    height:20px;
    outline:none;
}