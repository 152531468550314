.spinner-container {
    height: 50%;
    display:flex; 
    justify-content: center;
    align-items: center;
}

.next-label {
    width: 100%;
    justify-content: center;
    margin-top:40px;
}

.next {
    width: 100%;
    justify-content: center;
}

.next > .btn {
    margin-bottom: 20px;
    width: 100px;
}

.preuploaded.form-control {
    width: 100%;
}