.text-col {
    text-align: justify;
    text-justify: inter-word;
}

.text-col > img {
    margin-bottom: 10px;
}

.text-col > p > ul {
    list-style-type: square;
}

.text-col > p > ul > li {
    margin: 0
}