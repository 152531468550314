.TopBar {
    height: 70px;
    background-color: #97D18A;
    border-bottom: 1px solid grey;
}

.WebrankingLogo {
    flex: 0 0 230px;
    align-items: center;
}

.Logo > img {
    max-width: 100%;
    max-height: 100%;
}

.Page {
    height: calc(100vh - 70px);
    background-color: #e0e0e0;
}

.Menu {
    display: none;
    width: 230px;
    height: 100%;
    z-index: 1;
    position: fixed;
    background-color: white;
}

.MobileMenu {
    position: absolute;
    height: 100%;
    background-color: white;
}

.Content {
    display: flex;
    border-left: 230px solid white;
    background-color: #e0e0e0;
}

@media (max-width: 767px) { 
    .Content {
        border-left: 0;
    }
 }


.Content-container {
    margin-top: 3%;
}

.Content-box {
    margin-top: 3%;
    padding: 2%;
    background-color: white;
}

.btn {
    /* height: 40px; */
    /* width: 40px; */
    background-color: #5FA94F;
    border-color: green;
}

.btn:hover {
    background-color: #4FBD37;
}

.visibility-hidden {
    opacity: 0;
}

.hidden {
    display: none;
}

span {
    font-size: 1rem;
}

/* Icons */
svg {
    height: 1rem;
}

h1 > svg {
    height: 2rem;
    margin-bottom: 7px;
    margin-right: 10px;
}


/* Alerts */
.centered-alert{
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
}
  