.MenuContainer {
    margin: 0;
    padding: 0;
}

.ToolLogo {
    padding-top: 10%;
    padding-bottom: 10%;
    margin-bottom: 10%;
    margin-right: 0%;
    margin-left: 0%;
    justify-content: center;
    border-bottom: 1px solid gray;
}

.active_link {
    color: black;
}

ul {
    list-style-type: none;
}

ul > li {
    display: block;
    width: 100%;
    margin-left: 20px;
    margin-bottom: 15px;
}

li > a {
    display: block;
    text-decoration: none;
    color: gray;
}

li > a:hover {
    display: block;
    text-decoration: none;
    color: black;
}

/* Icons */
li > a > span > svg {
    width: 1rem;
    margin-right: 10px;
    margin-bottom: 3px;
}

.feedback-btn {
    height: 40px;
    width: 100px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s;
    margin-top: 20px;
    margin-left: 20px;
}
