.Logged {
    height: 100%;
    line-height: 1.2;
    font-size: 0.9em;
}

.ToggleButton {
    display: flex;
    align-items: center;
}

.LogoutButton {
    display: flex;
    align-items: center;
    border-left: 1px solid gray;
}